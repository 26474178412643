/** @jsx jsx */
import { jsx } from 'theme-ui'
import Slider from "react-slick";
import BackgroundImage from 'gatsby-background-image'
import { PrevArrow, NextArrow } from "./"

import "../../styles/slick.css"

export const ProductImages = ({product, slides="1"}) => {


  const settings = {
    infinite: true,
    centerMode: false,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: slides,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 10000,
        settins: 'unslick'
      }
    ]
  };
  
  return (
    <div sx={{display: ["block", "none", "none"]}}>
      <Slider {...settings}>
        {product.images.map((image, i) => (
          <div key={i} sx={{px: 1}}>
            <BackgroundImage
              fluid={image.localFile.childImageSharp.fluid}
              backgroundColor={`#000`}
              sx={{height: "300px", width: "100%"}}>
            </BackgroundImage>
          </div>
        ))}
      </Slider>
    </div>
  )
}