/** @jsx jsx */
import { jsx, Flex} from 'theme-ui'
import Slider from "react-slick";
import Img from "gatsby-image"

import "../../styles/slick.css"
import "../../styles/slick-theme.css"

export const PillowSlider = ({pillows, slides = 1}) => {


  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  console.log(pillows)
  
  return (
    <Slider {...settings} sx={{width: ["100%","100%","66%"], my: [0,0,6]}}>
      {pillows.map((pillow, i) => (
        <Flex key={i} sx={{backgroundColor: pillow.color, flexDirection: "column", justifyContent: "center", alignItems: "center",width: "100%",py: 6, borderRadius: "large", minHeight: ["50vh", "50vh","60vh"]}}>
          <div sx={{ width: "100%", maxWidth: "500px", mx: "auto"}}>
            <Img fluid={pillow.image} sx={{width: "100%"}} />
          </div>
        </Flex>
      ))}
    </Slider>
  )
}
